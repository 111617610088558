<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4 pb-2">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2 dense-wrapper">
            <v-col cols="8">
              <v-text-field type="text" hide-details filled label="Назва повна"
                            v-model="name"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="name ? '' : 'req-star'"
                            color="grey"
              />
            </v-col>
          <v-col cols="4">
            <v-checkbox
                v-model="social_normative"
                inset
                hide-details
                label="Соціальний норматив"
                color="success"
            />
          </v-col>
          <v-col cols="12" v-if="social_normative">
            <v-select :items="social_calc_type_select" filled label="Вид розрахунку пільги"
                      hide-details
                      v-model="social_calc_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="social_calc_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
            <v-col cols="12">
              <v-select :items="services" filled label="Послуга"
                        hide-details
                        v-model="service_id"
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="service_id ? '' : 'req-star'"
                        color="grey"
              />
            </v-col>
          <v-col cols="12">
            <v-switch
                v-model="only_square"
                inset
                hide-details class="mt-1"
                :label="only_square ? 'Норма по площі (включена)' : 'Норма по площі (виключена)'"
                color="success"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="only_square">
            <v-select :items="indicators_select" v-if="only_person"
                      hide-details
                      filled label="Вид показника проживаючих/зареєстрованих)"
                      v-model="indicator_id_person"
                      required color="grey"
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="indicator_id_person ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="only_square">
            <v-select :items="indicators_select" hide-details filled label="Вид показника площі"
                      v-model="indicator_id_square"
                      required color="grey"
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="indicator_id_square ? '' : 'req-star'"
            />
          </v-col>
            <v-col cols="6" v-if="only_square">
              <v-text-field type="text" hide-details filled label="Норма площі на 1 людину"
                            v-model.number="square_on_person"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_square">
              <v-text-field type="text" hide-details filled label="Норма площі на сім’ю"
                            v-model.number="square_on_family"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_square">
              <v-text-field type="text" hide-details filled label="Норма на метр квадратний (з буд.ліч)"
                            v-model.number="param_on_square_counter"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_square">
              <v-text-field type="text" hide-details filled label="Норма на метр квадратний (без буд.ліч)"
                            v-model.number="param_on_square_without"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="12" v-if="only_square">
              <v-switch
                  v-model="not_over_square"
                  inset
                  hide-details class="mt-1"
                  :label="not_over_square ? 'Норма в межах площі' : 'Норма не залежить від площі'"
                  color="success"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                  v-model="only_person"
                  inset
                  hide-details class="mt-1"
                  :label="only_person ? 'Норма на людину (включена)' : 'Норма на людину (виключена)'"
                  color="success"
              />
            </v-col>
          <v-col cols="12" md="12" v-if="only_person">
            <v-select :items="indicators_select" v-if="only_person"
                      hide-details
                      filled label="Вид показника проживаючих/зареєстрованих)"
                      v-model="indicator_id_person"
                      required color="grey"
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="indicator_id_person ? '' : 'req-star'"
            />
          </v-col>
            <v-col cols="6" v-if="only_person">
              <v-text-field type="text" hide-details filled label="Норма на 1 людину"
                            v-model.number="param_on_person"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_person">
              <v-text-field type="text" hide-details filled label="Норма на сім’ю"
                            v-model.number="param_on_family"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_person">
              <v-text-field type="text" hide-details filled label="Максимальна норма"
                            v-model.number="param_max_value"
                            v-decimal
                            color="grey"
              />
            </v-col>
            <v-col cols="6" v-if="only_person">
              <v-text-field type="text" hide-details filled label="Мінімальна к-сть чоловік у сім’ї"
                            v-model.number="param_on_person_min_count"
                            v-decimal
                            color="grey"
              />
            </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text tile color="success" @click.stop="updateService" :loading="getModalLoading">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {accountIcons, calculateMethodsSelect, social_calc_type_select, generateIconSelect} from "@/utils/icons";
import {CREATE_REGULARS, DELETE_REGULARS, UPDATE_REGULARS} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = "regular_modal_id"

export default {
  name: "HWP_Modal_Normative",
  mixins: [ModalComponentMixin],
  data() {
    return {
      calculateMethodsSelect,
      accountIcons,
      social_calc_type_select,
      indicator_id_square: this.item.indicator_id_square,
      indicator_id_person: this.item.indicator_id_person,
      service_id: this.item.service_id,
      name: this.item.name || '',
      square_on_person: this.item.square_on_person || 0,
      square_on_family: this.item.square_on_family || 0,
      param_on_square_counter: this.item.param_on_square_counter || 0,
      param_on_square_without: this.item.param_on_square_without || 0,
      param_on_person: this.item.param_on_person || 0,
      param_on_family: this.item.param_on_family || 0,
      param_on_person_min_count: this.item.param_on_person_min_count || 0,
      param_max_value: this.item.param_max_value || 0,
      not_over_square: this.item.not_over_square,
      only_square: this.item.only_square,
      only_person: this.item.only_person,
      social_normative: this.item.social_normative,
      social_calc_type: this.item.social_calc_type || null,
    }
  },
  computed: {
    ...mapGetters({
      indicators_select: 'getFlatIndicatorTypes',
      services: 'getServicesSelectWithoutAll',
    }),
    accountIconSelect() {
      return this.generateIconSelect()
    }
  },
  methods: {
    ...mapActions(
        {
          fetchIndicators: FETCH_FLAT_INDICATOR_TYPES
        }
    ),
    generateIconSelect,
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.name =  this.item.name || ''
      this.indicator_id_square = this.item.indicator_id_square || null
      this.indicator_id_person = this.item.indicator_id_person || null
      this.service_id = this.item.service_id || null
      this.square_on_person = this.item.square_on_person || 0
      this.square_on_family = this.item.square_on_family || 0
      this.param_on_square_counter = this.item.param_on_square_counter || 0
      this.param_on_square_without = this.item.param_on_square_without || 0
      this.param_on_person = this.item.param_on_person || 0
      this.param_on_family = this.item.param_on_family || 0
      this.param_on_person_min_count = this.item.param_on_person_min_count || 0
      this.param_max_value = this.item.param_max_value || 0
      this.not_over_square = this.item.not_over_square || false
      this.only_square = this.item.only_square
      this.only_person = this.item.only_person
      this.social_normative = this.item.social_normative
      this.social_calc_type = this.item.social_calc_type || null
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення нормативу ${this.name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля повинні бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        name: this.name,
        indicator_id_square: this.indicator_id_square || null,
        indicator_id_person: this.indicator_id_person || null,
        service_id: this.service_id,
        square_on_person: this.square_on_person,
        square_on_family: this.square_on_family,
        param_on_square_counter: this.param_on_square_counter,
        param_on_square_without: this.param_on_square_without,
        param_on_person: this.param_on_person,
        param_on_family: this.param_on_family,
        param_on_person_min_count: this.param_on_person_min_count,
        param_max_value: this.param_max_value,
        not_over_square: this.not_over_square || false,
        only_square: this.only_square || false,
        only_person: this.only_person || false,
        social_normative: this.social_normative || false,
        social_calc_type: this.social_calc_type || null
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      const operation = this.isNew ? CREATE_REGULARS : UPDATE_REGULARS

      this.$store.dispatch(operation, payload)
          .then(el => {
            if (el) {
              this.closeModal()
            }
          })
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.name =  payload.name || ''
              this.indicator_id_square = payload.indicator_id_square || null
              this.indicator_id_person = payload.indicator_id_person || null
              this.service_id = payload.service_id
              this.square_on_person = payload.square_on_person || 0
              this.square_on_family = payload.square_on_family || 0
              this.param_on_square_counter = payload.param_on_square_counter || 0
              this.param_on_square_without = payload.param_on_square_without || 0
              this.param_on_person = payload.param_on_person || 0
              this.param_on_family = payload.param_on_family || 0
              this.param_on_person_min_count = payload.param_on_person_min_count || 0
              this.param_max_value = payload.param_max_value || 0
              this.not_over_square = payload.not_over_square || false
              this.only_square = payload.only_square || false
              this.only_person = payload.only_person || false
              this.social_normative = payload.social_normative || false
              this.social_calc_type = payload.social_calc_type || null

              if (this.isNew && !this.indicators_select.length) {
                this.fetchIndicators()
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_REGULARS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>